export const formatTimestamp = (timestamp, includeSeconds = true) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    return date.toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: includeSeconds ? '2-digit' : undefined, // Include seconds only if specified
        hour12: false,
    });
};

/**
 * Get URL params;
 */
export const getParams = (params) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(params);
}

// Function to calculate chat duration
export const calculateChatDuration = (entryData) => {
    if (!entryData) {
        return "N/A"; // Return a fallback if data is incomplete
    }

    const startTime = new Date(entryData.created_at).getTime(); // Start time (milliseconds)
    const endTime = new Date(entryData.last_interaction).getTime(); // End time (milliseconds)

    const durationMs = endTime - startTime; // Duration in milliseconds

    // Convert milliseconds to a more readable format (minutes, seconds)
    const durationSeconds = Math.floor(durationMs / 1000); // Convert to seconds
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = durationSeconds % 60;

    // Return formatted duration
    return `${minutes}m ${seconds}s`;
};

export const resetUrlParams = () => {
    const url = new URL(window.location); // Get the current URL
    url.search = ''; // Remove all query parameters
    window.history.pushState({}, '', url); // Update the URL without query parameters
};

