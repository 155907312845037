// React
import React, { createContext, useContext, useEffect, useState } from 'react';

// Own
import { useKeycloak } from "@react-keycloak/web";

// reFetch table data every n seconds
export const FETCH_INTERVAL_TABLE = 3000;

// Define if session will be marked as recent (in milliseconds)
// example: timeNow - session.last_interaction < RECENT_SESSION
// TODO: set this back to 180000 when timestamp is fixed on BE side
// as workaround we set 1h + 3min to even server time
export const RECENT_SESSION = 3660000; // 61min
// 3780000 63 min

export const defaultFilters = {
    searchTerm: null,
    dates: { from: null, to: null },
    flagged: null,
    min_interaction: null,
    first_interaction: "",
    last_interaction: "",
    default_period: "30" // Analytics
};

const AppContext = createContext();

const AppProvider = ({children}) => {
    // Auth state
    const { keycloak, initialized } = useKeycloak();

    const [selectedApp, setSelectedApp] = useState();
    const [appReadyCallback, setAppReadyCallback] = useState(false);
    const [logEntries, setLogEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState();
    const [filters, setFilters] = useState(defaultFilters);
    const [filtersActive, setFiltersActive] = useState(false);
    const [filteringCallback, setFilteringCallback] = useState({state: false, pageReset: true});
    const [excelDownloadCallback, setExcelDownloadCallback] = useState(false);
    const [showFirstBotUtterance, setShowFirstBotUtterance] = useState(false);
    const [automaticSessionUpdate, setAutomaticSessionUpdate] = useState(false);
    const [hasRecentSessions, setHasRecentSessions] = useState(false);
    const [chatScrollCallback, setChatScrollCallback] = useState(false);

    /**
     * React on App change, clean data and inform (setAppReadyCallback)
     */
    useEffect(() => {

        // Reset prev values
        setLogEntries([]);
        setSelectedEntry(null);
        setFiltersActive(false);
        setShowFirstBotUtterance(false);
        setAutomaticSessionUpdate(false);

        if (selectedApp) {
            // Set default filters once App available
            // first/last_interaction are used for min/max for date pickers
            setFilters({
                ...defaultFilters,
                first_session: selectedApp.first_interaction,
                last_session: selectedApp.last_interaction
            });

            // And inform who is interested
            setAppReadyCallback(true);
        }
    }, [selectedApp])

    /**
     * Handle active filters and filters active state
     * @returns {string} params that will be used to fetch data
     */
    const handleFilters = () => {
        let searchTerm = '';
        let dateFrom = '';
        let dateTo = '';
        let flagged = '';
        let minInteraction = '';

        searchTerm = filters.searchTerm ? `&search_query=${filters.searchTerm}` : '';
        dateFrom = filters.dates.from ? `&from_datetime=${filters.dates.from}` : '';
        dateTo = filters.dates.to ? `&to_datetime=${filters.dates.to}` : '';
        flagged = filters.flagged != null ? `&is_tagged=${filters.flagged}` : '';
        minInteraction = filters.min_interaction !== null ? `&min_interaction=${filters.min_interaction}` : '';

        const queryString = `${searchTerm}${dateFrom}${dateTo}${flagged}${minInteraction}`;

        setFiltersActive(queryString.length > 0);

        return queryString;
    };

    return (
        <AppContext.Provider
            value={{
                selectedApp,
                setSelectedApp,
                appReadyCallback,
                setAppReadyCallback,
                logEntries,
                setLogEntries,
                selectedEntry,
                setSelectedEntry,
                filters,
                setFilters,
                filteringCallback,
                setFilteringCallback,
                filtersActive,
                setFiltersActive,
                keycloak,
                initialized,
                excelDownloadCallback,
                setExcelDownloadCallback,
                showFirstBotUtterance,
                setShowFirstBotUtterance,
                automaticSessionUpdate,
                setAutomaticSessionUpdate,
                chatScrollCallback,
                setChatScrollCallback,
                handleFilters
            }}>
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, useAppContext };
