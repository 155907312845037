// React
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

// Own
import ActionButtons from "./action-buttons";
import NoDataView from "./no-data-view";
import { handleData } from "../../utils/data-handler";
import { useTableColumns } from "./useTableColumns";
import { FETCH_INTERVAL_TABLE, useAppContext } from "../../store/appContext";

const Table = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [pending, setPending] = useState(false);

    // Pagination state
    const [totalRows, setTotalRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    // For handling session and app ID URL params
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        selectedApp,
        appReadyCallback,
        setAppReadyCallback,
        logEntries,
        setLogEntries,
        selectedEntry,
        setSelectedEntry,
        filteringCallback,
        setFilteringCallback,
        handleFilters,
        automaticSessionUpdate,
        setAutomaticSessionUpdate,
        setChatScrollCallback
    } = useAppContext();

    /**
     * Custom hook to manage table column configurations based on the table's width.
     */
    const {tableRef, columns} = useTableColumns();

    const logEntriesRef = useRef(logEntries);
    const selectedEntryRef = useRef(selectedEntry);

    // Get the data
    useEffect(() => {
        if (selectedApp && appReadyCallback) {
            fetchLogEntries()
        }

        return () => setAppReadyCallback(false);

    }, [appReadyCallback]);


    // Get data / pagination
    useEffect(() => {
        if (selectedApp) fetchLogEntries();

    }, [currentPage, perPage]);


    // Trigger filtering
    useEffect(() => {
        if (filteringCallback.state) {
            setSelectedRows([]);
            setToggleClearRows(!toggledClearRows);

            if (filteringCallback.pageReset) {
                setCurrentPage(1);
            }

            fetchLogEntries();
        }

        return () => setFilteringCallback({state: false, pageReset: true});
    }, [filteringCallback.state]);


    // Start Automatic Fetching
    // setTimeout recursion used, which creates closure over data in fetchLogEntries:
    // we use reference for log entries (logEntriesRef) so data is always updated, even in closure.
    useEffect(() => {
        let timeoutId;

        const fetchAndSchedule = () => {
            fetchLogEntries();
            timeoutId = setTimeout(fetchAndSchedule, FETCH_INTERVAL_TABLE);
        };

        if (automaticSessionUpdate) {
            // console.log("Automatic update started");
            fetchAndSchedule();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                // console.log("Automatic update stopped");
            }
        };
    }, [automaticSessionUpdate]);


    /**
     * Get Sessions for selected APP, current page, size per page and with filters.
     */
    const fetchLogEntries = () => {
        const filterParams = handleFilters();
        const api = `${process.env.REACT_APP_API_URL}/applications/${selectedApp.id}?page=${currentPage}&size=${perPage}${filterParams}`;

        if (!automaticSessionUpdate) setPending(true);

        handleData("GET", api)
            .then(response => {
                if (!response.items.length) {
                    resetLogEntries();
                    return;
                }

                updateLogEntries(response);

                automaticSessionUpdate ? updateSelectedEntry(response.items) : handleSelectedEntry(response.items);

            })
            .catch(error => {
                console.error("Error fetching log entries:", error.message);
                toast.error("Failed to fetch log entries");
                setAutomaticSessionUpdate(false);
            })
            .finally(() => setPending(false));
    };

    const updateLogEntries = (newEntries) => {
        if (JSON.stringify(logEntriesRef.current) !== JSON.stringify(newEntries.items)) {
            setLogEntries(newEntries.items);
            logEntriesRef.current = newEntries.items;
            setTotalRows(newEntries.total);
        }
    };

    const handleSelectedEntry = (entries) => {
        const deepLinkSessionId = searchParams.get("sessionId");
        const selectedEntry = entries.find(item => item.session_id === deepLinkSessionId) || entries[0];

        if (selectedEntryRef.current?.session_id !== selectedEntry.session_id) {
            setSelectedEntry(selectedEntry);
            handleDeepLink(selectedEntry.session_id);
        }
    };

    const updateSelectedEntry = (entries) => {
        const selectedEntryNew = entries.find(item => item.session_id === selectedEntryRef.current?.session_id);

        if (selectedEntryNew && selectedEntryNew.chatLog.length > selectedEntryRef.current.chatLog.length) {
            setSelectedEntry(prevEntry => ({
                ...prevEntry,
                chatLog: [...selectedEntryNew.chatLog]
            }));

            setChatScrollCallback(true);
        }
    };

    const resetLogEntries = () => {
        setLogEntries([]);
        setSelectedEntry(null);
        setTotalRows(0);
        setSearchParams({ appId: selectedApp.id });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const onRowClicked = (row) => {
        setSelectedEntry(row);
        handleDeepLink(row.session_id);
        selectedEntryRef.current = row;
    }

    const handleDeepLink = (sessionId) => {
        setSearchParams({ appId: selectedApp.id, sessionId: sessionId });
    };

    const handleExcelDownload = (quantity) => {
        const filterParams = handleFilters();

        // Selected items are sessions clicked (selected) in table
        const selectedIDs = selectedRows.map(item => item.session_id).toString();

        // If no selected items, user chose quantity of downloadable items
        const items = quantity ? quantity : selectedIDs;

        const paramName = quantity ? 'quantity' : 'session_ids';

        const api = `${process.env.REACT_APP_API_URL}/applications/${selectedApp.id}/excel?${paramName}=${items}${filterParams}`;

        handleData("GET", api, null, {}, "blob")
            .then(blob => {
                const now = new Date();

                const formatDatePart = (part) => String(part).padStart(2, '0');

                const year = now.getFullYear();
                const month = formatDatePart(now.getMonth() + 1);
                const day = formatDatePart(now.getDate());
                const hours = formatDatePart(now.getHours());
                const minutes = formatDatePart(now.getMinutes());
                const seconds = formatDatePart(now.getSeconds());

                const timestamp = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
                const fileName = `loggi_${selectedApp.name}_${timestamp}.xlsx`; // make APP name dynamic

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();

                toast.success(`File "${fileName}" downloaded successfully!`, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch(error => {
                console.error("Error downloading Excel:", error);
                toast.error('Failed to download the Excel.');
            });
    }

    return (
        <div ref={tableRef}>
            <DataTable
                columns={columns}
                data={logEntries}
                pagination
                paginationServer
                highlightOnHover
                pointerOnHover
                noDataComponent={<NoDataView selectedAppId={selectedApp?.id}/>}
                progressPending={pending}
                onRowClicked={onRowClicked}
                fixedHeader
                fixedHeaderScrollHeight={'600px'}
                selectableRows
                onSelectedRowsChange={({selectedRows}) => setSelectedRows(selectedRows)}
                clearSelectedRows={toggledClearRows}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                subHeader
                subHeaderComponent={
                    <ActionButtons
                        selectedRows={selectedRows}
                        totalRows={totalRows}
                        hasFilter={totalRows}
                        excelDownloadCallback={(quantity) => handleExcelDownload(quantity)}/>
                }
                conditionalRowStyles={[
                    {
                        when: row => row.session_id === selectedEntry?.session_id,
                        style: {
                            backgroundColor: '#2196F329',
                        },
                    },
                ]}
            />

        </div>
    );
};

export default React.memo(Table);
