import { calculateChatDuration, formatTimestamp } from "../../utils/helpers";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import { RECENT_SESSION } from "../../store/appContext";
import { useAppContext } from "../../store/appContext";
import { handleData } from "../../utils/data-handler";
import { toast } from "react-toastify";

export const getColumns = (tableWidth, changeTableView, automaticSessionUpdate, sessionData, selectedApp, setFilteringCallback) => {
    const timeNow = Date.now();

    const toggleTagged = (session) => {
        const newTaggedState = !session.tagged;
        const api = `${process.env.REACT_APP_API_URL}/sessions/${selectedApp.id}/${session.session_id}?tagged=${newTaggedState}`;

        handleData("PATCH", api, null, {}, "text")
            .then(() => {
                session.tagged = newTaggedState; // Update local state
                setFilteringCallback({ state: true, pageReset: false });

                toast.success(`Session updated successfully!`, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((error) => {
                console.error("Error updating session:", error);
                toast.error("Failed to update session");
            });
    };

    // Standard columns
    const columns = [
        {
            name: 'ID',
            selector: row => row.session_id.length > 6 ? row.session_id.slice(-6) : row.session_id,
            width: "100px"
        },
        {
            name: 'Started',
            selector: row => new Date(row.created_at).getTime(), 
            sortable: true,
            width: "150px",
            cell: row => {
                const formattedDate = formatTimestamp(row.created_at, false);
                return <div style={{ pointerEvents: 'none' }}>{formattedDate}</div>;
            },
        },
        {
            name: 'Duration',
            sortable: true,
            width: "120px",
            selector: row => {
                const startTime = new Date(row.created_at).getTime();
                const endTime = new Date(row.last_interaction).getTime();
                return (endTime - startTime) / 1000; 
            },
            cell: row => {
                const endTime = new Date(row.last_interaction).getTime();
                const isRecent = timeNow - endTime < RECENT_SESSION;
                return (isRecent && automaticSessionUpdate) ? 
                    <span className="recent-indicator is-in-table"></span> 
                    : calculateChatDuration(row);
            }
        },
        {
            name: 'Conversation',
            selector: row => row.chatLog,
            grow: 4,
            cell: row => (
                <div style={{ cursor: 'pointer', pointerEvents: 'none' }}>
                    {row.chatLog[changeTableView ? 0 : 1] && (
                        <div style={{ color: `${changeTableView ? '#8D3CFF' : '#2196F3'}`, padding: '5px 0 0' }}>
                            {row.chatLog[changeTableView ? 0 : 1].message.length > 100
                                ? row.chatLog[changeTableView ? 0 : 1].message.substring(0, 100) + '...'
                                : row.chatLog[changeTableView ? 0 : 1].message}
                        </div>
                    )}
                    {row.chatLog[changeTableView ? 1 : 2] && (
                        <div style={{ color: `${changeTableView ? '#2196F3' : '#8D3CFF'}`, padding: '5px 0' }}>
                            {row.chatLog[changeTableView ? 1 : 2].message.length > 100
                                ? row.chatLog[changeTableView ? 1 : 2].message.substring(0, 100) + '...'
                                : row.chatLog[changeTableView ? 1 : 2].message} {`(${row.chatLog.length})`}
                        </div>
                    )}
                </div>
            ),
            wrap: true,
            maxHeight: "50px",
        },
        {
            name: '',
            selector: row => (
                row.tagged ? (
                    <AssistantPhotoIcon onClick={() => toggleTagged(row)}
                        style={{ color: 'black', flexShrink: '0' }}
                    />
                ) : (
                    <OutlinedFlagIcon onClick={() => toggleTagged(row)}
                        style={{ color: 'grey', flexShrink: '0' }}
                    />
                )
            ),
            width: "60px",
            ignoreRowClick: true,
            omit: tableWidth < 100
        }
    ];

    // Dynamically add custom columns based on session_info
    if (sessionData && sessionData.length > 0) {
        const customColumns = sessionData[0].session_info && Array.isArray(sessionData[0].session_info)
            ? sessionData[0].session_info
                .filter(info => info.is_custom_column) // Only include custom columns
                .map(info => ({
                    name: info.name, // Custom column name
                    selector: row => {
                        const field = row.session_info.find(item => item.name === info.name);
                        return field ? field.value || "" : "";
                    },
                    width: "150px",
                    cell: row => {
                        const field = row.session_info.find(item => item.name === info.name);
                        return <div>{field ? field.value || "" : ""}</div>;
                    }
                }))
            : [];

        columns.splice(columns.length - 1, 0, ...customColumns);
    }

    return columns;
};
