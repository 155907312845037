// React
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

// Mui
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, LinearProgress } from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";

// Own
import Header from "../../components/header/header";
import { handleData } from "../../utils/data-handler";
import { defaultFilters, useAppContext } from "../../store/appContext";
import AnalyticFilters from "./analytic-filters";
import { formatDuration, getChartData } from "./process-data";


const API_URL = process.env.REACT_APP_API_URL;

// TEMP: when deleting api is ready on BE, delete this session, and remove related code;
const excludedSessionIds = ["03f5580f-3f85-4a47-af08-c422da9875dc"];

const Analytics = () => {
    const {selectedApp} = useAppContext();

    const [logEntries, setLogEntries] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [axisData, setAxisData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [filterType, setFilterType] = useState('all');

    // Set app ID URL params
    const [searchParams, setSearchParams] = useSearchParams();

    // Trigger filtering with default filter period
    useEffect(() => {
        if (selectedApp) {
            fetchLogEntries(getDefaultPeriod());
        }
    }, [selectedApp]);

    // Process logEntries when they are loaded
    useEffect(() => {
        if (logEntries) {
            const data = getChartData(logEntries, excludedSessionIds);
            console.log(data);
            setTableData(data);
        }
    }, [logEntries]);

    const fetchLogEntries = (dateRange = '') => {
        setLoading(true);

        setLogEntries(null);
        setAxisData(null);
        setTableData(null);
        setFilterType('all');

        const filters = dateRange
            ? `&from_datetime=${dateRange.from}&to_datetime=${dateRange.to}`
            : '';

        const api = `${API_URL}/applications/${selectedApp.id}?min_interaction=1${filters}`;

        handleData("GET", api)
            .then(response => {
                setLogEntries(response.items);
                setSearchParams({ appId: selectedApp.id });
                setTimeout(() => setLoading(false), 1000);
            })
            .catch(error => {
                console.log(error);
                setTimeout(() => setLoading(false), 1000);
            });
    };

    function getDefaultPeriod() {
        const today = dayjs();
        const dateFrom = today.subtract(Number(defaultFilters.default_period), "day");

        return {from: dayjs(dateFrom).toISOString(), to: dayjs(today).toISOString()};
    }

    return (
        <>
            <Header/>

            <div className="analytics-page">
                <h2 className="headline">Analytics</h2>

                {selectedApp ? (
                    <div className="filters">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <AnalyticFilters
                                filterTypeCallback={(filterType) => setFilterType(filterType)}
                                filterPeriodCallback={(dateRange) => fetchLogEntries(dateRange)}
                            />
                        </LocalizationProvider>
                    </div>
                ) : (
                    <p>Select an App</p>
                )}


                <div className="analytics-container">
                    <div className="charts">
                        {/* Loading and Error States */}
                        {loading ? (
                            <Box sx={{width: '840px', marginLeft: '20px', marginTop: '60px'}}>
                                <LinearProgress/>
                            </Box>
                        ) : logEntries && logEntries.length === 0 ? (
                            <Box sx={{marginLeft: '40px', marginTop: '60px'}}>
                                <p>No data available.</p>
                            </Box>
                        ) : (
                            tableData && (
                                <div className="chart-wrapper">
                                    {(filterType === 'all' || filterType === 'conversations') &&
                                        <BarChart
                                            xAxis={[
                                                {
                                                    id: "barCategories",
                                                    data: tableData.days,
                                                    scaleType: "band",
                                                },
                                            ]}
                                            series={[
                                                {
                                                    id: 'sessions',
                                                    data: tableData.totalSessions,
                                                    label: 'Number of conversations per day',
                                                }
                                            ]}
                                            onAxisClick={(event, d) => setAxisData(d)}
                                            height={400}
                                        />
                                    }

                                    {(filterType === 'all' || filterType === 'duration') &&
                                        <LineChart
                                            xAxis={[
                                                {
                                                    id: "barCategories",
                                                    data: tableData.days,
                                                    scaleType: "band",
                                                },
                                            ]}
                                            series={[
                                                {
                                                    id: 'sessions',
                                                    data: tableData.averageSessionDuration,
                                                    label: 'Average session duration',
                                                    valueFormatter: (value) => formatDuration(value)
                                                }
                                            ]}
                                            onAxisClick={(event, d) => setAxisData(d)}
                                            height={400}
                                        />
                                    }

                                    {(filterType === 'all' || filterType === 'interactions') &&
                                        <LineChart
                                            xAxis={[
                                                {
                                                    id: "barCategories",
                                                    data: tableData.days,
                                                    scaleType: "band",
                                                },
                                            ]}
                                            series={[
                                                {
                                                    id: 'sessions',
                                                    data: tableData.averageSessionInteraction,
                                                    label: 'Average interactions per session'
                                                }
                                            ]}
                                            onAxisClick={(event, d) => setAxisData(d)}
                                            height={400}
                                        />
                                    }

                                    {(filterType === 'all' || filterType === 'empty') &&
                                        <LineChart
                                            xAxis={[
                                                {
                                                    id: "barCategories",
                                                    data: tableData.days,
                                                    scaleType: "band",
                                                },
                                            ]}
                                            series={[
                                                {
                                                    id: 'totalSessions',
                                                    data: tableData.totalSessions,
                                                    label: 'Total sessions per day'
                                                },
                                                {
                                                    id: 'emptyConversations',
                                                    data: tableData.emptyConversations,
                                                    label: 'Empty conversations per day'
                                                }
                                            ]}
                                            onAxisClick={(event, d) => setAxisData(d)}
                                            height={400}
                                        />
                                    }
                                </div>
                            )
                        )}
                    </div>

                    {/* Sticky Details Block */}
                    {tableData && !loading && logEntries && logEntries.length > 0 && (
                        <div className="analytics-details">
                            {axisData ? (
                                <>
                                    <p><strong>{tableData.days[axisData.dataIndex]}.2024</strong></p>
                                    <p>{tableData.totalSessions[axisData.dataIndex]} conversations</p>
                                    <hr/>
                                    <p>Average interactions per
                                        session {tableData.averageSessionInteraction[axisData.dataIndex]}</p>

                                    <p>Average duration: {formatDuration(tableData.averageSessionDuration[axisData.dataIndex])}</p>

                                    <p>
                                        Shortest session:{" "}
                                        {tableData.minMaxConversation[axisData.dataIndex]
                                            ? formatDuration(tableData.minMaxConversation[axisData.dataIndex][0])
                                            : "No data"}
                                    </p>
                                    <p>
                                        Longest session:{" "}
                                        {tableData.minMaxConversation[axisData.dataIndex]
                                            ? formatDuration(tableData.minMaxConversation[axisData.dataIndex][1])
                                            : "No data"}
                                    </p>
                                    <p>
                                        Empty session:{" "}
                                        {tableData.emptyConversations[axisData.dataIndex] !== undefined
                                            ? `${tableData.emptyConversations[axisData.dataIndex]} of ${tableData.totalSessions[axisData.dataIndex]}`
                                            : "No data"}
                                    </p>
                                    <p>
                                        Unengaged Conversation Rate:{" "}
                                        {tableData.emptyConversations[axisData.dataIndex] !== undefined
                                            ? `${Math.round((tableData.emptyConversations[axisData.dataIndex] / tableData.totalSessions[axisData.dataIndex]) * 100)}%`
                                            : "No data"}
                                    </p>
                                </>
                            ) : (
                                <p>Click on chart for more details</p>
                            )}
                        </div>
                    )}

                </div>

            </div>
        </>
    );
};


export default Analytics;
