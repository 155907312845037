import { formatTimestamp } from "../../utils/helpers";
import { useAppContext } from "../../store/appContext";
import { useEffect, useRef } from "react";

export default function Chat() {
    const { selectedEntry, filters, filtersActive, chatScrollCallback, setChatScrollCallback } = useAppContext();

    const chatAreaRef = useRef(null);

    useEffect(() => {
        if (filtersActive && filters.searchTerm) {
            setTimeout(() => {
                const firstMark = document.querySelector(".first-mark");
                if (firstMark) {
                    firstMark.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 0);
        }
    }, [filtersActive, filters.searchTerm, selectedEntry]);

    // If chat conversations are updated, callback triggers scroll to bottom
    useEffect(() => {
        if (chatScrollCallback && chatAreaRef.current) {
            chatAreaRef.current.scrollTo({
                top: chatAreaRef.current.scrollHeight,
                behavior: 'smooth',
            });
            setChatScrollCallback(false);
        }
    }, [chatScrollCallback]);

    const escapeRegExp = (string) =>
        string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapes special characters

    let markedFirst = false; // Track the first match

    return (
        <div ref={chatAreaRef} className="chat-area">
            {selectedEntry.chatLog.map((entry, index) => {
                const messageContent = filters.searchTerm
                    ? entry.message.replace(
                        new RegExp(escapeRegExp(filters.searchTerm), "gi"),
                        (match) => {
                            if (!markedFirst) {
                                markedFirst = true;
                                return `<mark class="first-mark">${match}</mark>`;
                            }
                            return `<mark>${match}</mark>`;
                        }
                    )
                    : entry.message;

                return (
                    <div key={index} className={entry.sender === "USER" ? "chat-row is-user" : "chat-row"}>
                        <div className="chat-bubble">
                            <div
                                className="chat-message"
                                dangerouslySetInnerHTML={{ __html: messageContent }}
                            ></div>
                            <div className="chat-meta">
                                <small>
                                    <span className="message-type">{entry.sender === "USER" ? "User " : "Bot "}</span>
                                    <span className="time-stamp">{formatTimestamp(entry.timestamp)}</span>
                                </small>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
