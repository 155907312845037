// React
import React, { useEffect, useState } from "react";

// Mui
import { IconButton, InputAdornment, TextField, Divider, FormControlLabel, Switch } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// Own
import { useAppContext } from "../../store/appContext";
import FlagFilter from "./flag-filter";
import InteractionFilter from "./interaction-filter";

const datePickerProps = {
    textField: { size: "small", variant: "standard" },
    actionBar: { actions: ["accept", "today", "clear"] },
};

export default function Filters() {
    const { 
        filters, 
        setFilters, 
        setFilteringCallback,         
        automaticSessionUpdate,
        setAutomaticSessionUpdate 
    } = useAppContext();

    const [searchQuery, setSearchQuery] = useState('');

    // If filters reset, reset also local state
    useEffect(() => {
        if (!filters.searchTerm) {
            setSearchQuery('');
        }
    }, [filters.searchTerm])

    const triggerSearch = () => {
        setFilteringCallback({state: true, pageReset: true});
    };

    const handleSearchTermChange = (event) => {
        if (event === "clear" || (event && event.target?.value.length === 0)) {
            setFilters((prevFilters) => ({ ...prevFilters, searchTerm: '' }));
            setSearchQuery('');
            triggerSearch();
            return;
        }

        setSearchQuery(event.target?.value);
    };

    const handleDateChange = (newDate, type) => {
        const updatedDates = {
            ...filters.dates,
            [type]: newDate ? dayjs(newDate).toISOString() : null,
        };

        setFilters((prevFilters) => ({ ...prevFilters, dates: updatedDates }));
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter' && searchQuery.length > 3) {
            setFilters((prevFilters) => ({ ...prevFilters, searchTerm: searchQuery }));
            triggerSearch();
        }

        if (event.key === 'Escape') {
            setFilters((prevFilters) => ({ ...prevFilters, searchTerm: '' }));
            setSearchQuery('');
            triggerSearch();
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="filters-wrapper">
                <div className="filters-row">
                    <TextField
                        fullWidth
                        label="Search for conversation content or sessionID"
                        variant="standard"
                        value={searchQuery}
                        onChange={handleSearchTermChange}
                        onKeyDown={handleKeyDown}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end"
                                                onClick={() =>  {
                                                    setFilters((prevFilters) => ({ ...prevFilters, searchTerm: searchQuery }));
                                                    triggerSearch();
                                                }}
                                                disabled={searchQuery.length < 3}>
                                        <SearchIcon/>
                                    </IconButton>
                                    <Divider sx={{height: 28, m: 0.5, marginLeft: 1.5}} orientation="vertical"/>
                                    <IconButton onClick={() => handleSearchTermChange("clear")}
                                                disabled={searchQuery.length < 3}>
                                        <HighlightOffIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className="filters-row">

                    {/* DateTime Pickers */}
                    <div className="filters-row-dates-wrapper">
                        <DateTimePicker
                            label="From"
                            value={filters.dates.from ? dayjs(filters.dates.from) : null}
                            onChange={(newDate) => handleDateChange(newDate, "from")}
                            ampm={false}
                            slotProps={datePickerProps}
                            className="date-picker"
                            minDate={dayjs(filters.first_session)} // validation
                            maxDate={dayjs(filters.last_session).subtract(1, "day")} // validation
                            onAccept={() => triggerSearch()}
                        />
                        <DateTimePicker
                            label="To"
                            value={filters.dates.to ? dayjs(filters.dates.to) : null}
                            onChange={(newDate) => handleDateChange(newDate, "to")}
                            ampm={false}
                            slotProps={datePickerProps}
                            className="date-picker"
                            minDate={dayjs(filters.dates.from).add(1, "day")} // validation
                            maxDate={dayjs(filters.last_session)} // validation
                            onAccept={() => triggerSearch()}
                        />
                    </div>

                    <FlagFilter/>

                    <InteractionFilter/>
                </div>
                <FormControlLabel
                    control={<Switch size="small" checked={automaticSessionUpdate}/>}
                    label="Live View"
                    labelPlacement="end"
                    onChange={(e) => {
                        setAutomaticSessionUpdate(e.target.checked)
                    }}
                />
            </div>
        </LocalizationProvider>
    );
}
