import Keycloak from "keycloak-js";

// Enable when BE implement ENVs
// const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
// const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
// const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

const keycloakUrl = "https://keycloak.sliplane.app/";
const keycloakRealm = "loggi";
const keycloakClientId = "loggi-client";

const keycloak = new Keycloak({
    url: keycloakUrl,
    realm: keycloakRealm,
    clientId: keycloakClientId
});

export default keycloak;
