// React
import React, { useEffect, useState } from "react";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";

// Own
import { handleData } from "../../utils/data-handler";
import AccountMenu from "./account-menu";
import { getParams } from "../../utils/helpers";
import { useAppContext } from "../../store/appContext";

export default function Header() {
    const {keycloak, initialized} = useAppContext();

    const [applications, setApplications] = useState([]);

    const {selectedApp, setSelectedApp} = useAppContext();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            getApplications();
        }
    }, [keycloak.authenticated]);

    const getApplications = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/applications/`;
        const deepLinkAppId = getParams('appId');

        handleData("GET", apiUrl)
            .then((response) => {
                if (response.items.length > 0) {
                    setApplications(response.items);

                    // Check if there is company set in URL param
                    const company = deepLinkAppId && response.items.find(item => item.id === Number(deepLinkAppId));

                    // If yes, use it, else select first one
                    if (company) {
                        setSelectedApp(company)
                    } else {
                        setSelectedApp(response.items[0]);
                    }
                } else {
                    console.log('No application available')
                }
            })
            .catch((error) => {
                console.error("Failed to fetch applications:", error.message);
            });
    };

    const handleSelectApp = (appId) => {
        const selectedApp = applications.find(app => app.id === Number(appId));

        if (selectedApp) setSelectedApp(selectedApp);
    }

    // Determine active tab based on current URL
    const activeTab = location.pathname.includes("/analytics") ? "analytics" : "logs";

    const handleTabClick = (tabName) => {
        if (tabName !== activeTab) {
            navigate(tabName === "logs" ? "/" : "/analytics");
        }
    };

    return (
        <div className="header">
            <div className="logo">
                <span className="headline">Loggi</span>
            </div>

            <nav className="nav">
                <div
                    className={`nav-item ${activeTab === "logs" ? "is-active" : ""}`}
                    onClick={() => handleTabClick("logs")}>
                    Conversation logs
                </div>
                {selectedApp?.id !== 4 && (
                    <div className={`nav-item ${activeTab === "analytics" ? "is-active" : ""}`}
                        onClick={() => handleTabClick("analytics")}>
                        Analytics
                    </div>
                )}
            </nav>

            <div className="user">
                {applications &&
                    <FormControl sx={{m: 1, minWidth: 220, backgroundColor: 'transparent'}} size="small">
                        <Select
                            sx={{borderBottom: '1px solid white'}}
                            value={selectedApp?.id || 'DEFAULT'}
                            placeholder="Select an App"
                            style={{padding: '5px 5px 0', fontSize: '16px'}}
                            variant="standard"
                            onChange={(e) => handleSelectApp(e.target.value)}
                        >
                            <MenuItem value="DEFAULT" disabled>Select an App</MenuItem>
                            {applications.map((app) => (
                                <MenuItem key={app.id} value={app.id.toString()}>{app.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }

                <AccountMenu selectedApp={selectedApp}/>
            </div>
        </div>
    )
}
