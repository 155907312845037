export const getChartData = (logEntries, excludedSessionIds = []) => {
    if (!logEntries || logEntries.length === 0) {
        return {
            days: [],
            totalSessions: [],
            averageSessionDuration: [],
            averageSessionInteraction: [],
            minMaxConversation: [],
            emptyConversations: []
        };
    }

    const firstConversationTimeStamp = logEntries[logEntries.length - 1].created_at;
    const lastConversationTimeStamp = logEntries[0].created_at;

    const days = getDaysBetweenDates(firstConversationTimeStamp, lastConversationTimeStamp);

    const tableData = {
        days: [],
        totalSessions: [],
        averageSessionDuration: [],
        averageSessionInteraction: [],
        minMaxConversation: [],
        emptyConversations: []
    };

    days.forEach((day) => {
        // Filter log entries for the specific day and exclude specified sessions
        const sessionsForDay = logEntries.filter((entry) => {
            const entryDate = new Date(entry.created_at);
            const formattedEntryDate = `${entryDate.getUTCDate().toString().padStart(2, "0")}.${(
                entryDate.getUTCMonth() + 1
            )
                .toString()
                .padStart(2, "0")}`;
            return (
                formattedEntryDate === day &&
                !excludedSessionIds.includes(entry.session_id) // Exclude sessions by ID
            );
        });

        // Add default values for the day if no valid sessions
        if (sessionsForDay.length === 0) {
            tableData.days.push(day);
            tableData.totalSessions.push(0);
            tableData.averageSessionDuration.push(0);
            tableData.averageSessionInteraction.push(0);
            tableData.minMaxConversation.push([0, 0]);
            tableData.emptyConversations.push(0);
            return;
        }

        // If valid sessions exist, calculate metrics
        const totalSessions = sessionsForDay.length;

        // Calculate session durations in total seconds
        const sessionDurations = sessionsForDay
            .map((session) => {
                const chatLog = session.chatLog;
                if (chatLog && chatLog.length > 1) {
                    const firstTimestamp = new Date(chatLog[0].timestamp).getTime();
                    const lastTimestamp = new Date(chatLog[chatLog.length - 1].timestamp).getTime();
                    return (lastTimestamp - firstTimestamp) / 1000; // in total seconds
                }
                return null;
            })
            .filter((duration) => duration !== null);

        // Calculate the average session duration as the mean of all durations (in total seconds)
        const averageSessionDuration =
            sessionDurations.length > 0
                ? sessionDurations.reduce((sum, duration) => sum + duration, 0) / sessionDurations.length
                : 0;

        // Calculate min and max durations in seconds
        const minDuration = sessionDurations.length > 0 ? Math.min(...sessionDurations) : 0;
        const maxDuration = sessionDurations.length > 0 ? Math.max(...sessionDurations) : 0;

        // Calculate interactions per session
        const totalInteractions = sessionsForDay
            .map((session) => session.chatLog.length)
            .filter((interactions) => interactions > 1);

        const averageSessionInteraction =
            totalInteractions.length > 0
                ? Math.round(totalInteractions.reduce((sum, count) => sum + count, 0) / totalInteractions.length)
                : 0;

        // Filter sessions where chatLog contains only a BOT message
        const emptyConversations = sessionsForDay.filter((session) => {
            return session.chatLog.length === 1;
        }).length; // Get the count of empty conversations for the day

        // Push calculated values into tableData
        tableData.days.push(day);
        tableData.totalSessions.push(totalSessions);
        tableData.averageSessionDuration.push(averageSessionDuration); // Store total seconds
        tableData.averageSessionInteraction.push(averageSessionInteraction);
        tableData.minMaxConversation.push([minDuration, maxDuration]);
        tableData.emptyConversations.push(emptyConversations);
    });

    return tableData;
};


const getDaysBetweenDates = (startDate, endDate) => {
    const days = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Use UTC for consistent date handling
    for (let date = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate()));
         date <= end;
         date.setUTCDate(date.getUTCDate() + 1)) {
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        days.push(`${day}.${month}`);
    }

    return days;
};

// Helper function to format seconds into minutes and seconds
export const formatDuration = (totalSeconds) => {
    if (totalSeconds === null || totalSeconds === undefined) return "Invalid duration";

    const hours = Math.floor(totalSeconds / 3600); // Calculate hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
    const seconds = Math.floor(totalSeconds % 60); // Calculate seconds

    // If the duration is over 1 hour, display hours
    if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    // Otherwise, display just minutes and seconds
    return `${minutes}m ${seconds}s`;
};
