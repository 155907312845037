// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import keycloak from './auth/keycloakService';
import { ReactKeycloakProvider } from "@react-keycloak/web";

// Own
import App from './App';
import ErrorPage from './components/error-page';
import AppSettings from './views/admin/app-settings';
import UserManagement from './views/admin/user-management';
import Analytics from './views/analytics/analytics';
import { AppProvider } from './store/appContext';

// Style
import './index.scss';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
    },
    {
        path: "user-management",
        element: <UserManagement />,
        errorElement: <ErrorPage />
    },
    {
        path: "settings",
        element: <AppSettings />,
        errorElement: <ErrorPage />
    },
    {
        path: "analytics",
        element: <Analytics />,
        errorElement: <ErrorPage />
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
            onLoad: "login-required", // Enforce login immediately
            checkLoginIframe: false,
        }}>
        <AppProvider>
            <RouterProvider router={router} />
        </AppProvider>
    </ReactKeycloakProvider>
);
