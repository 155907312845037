// React
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

// Mui
import { IconButton } from "@mui/material";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Own
import { calculateChatDuration, formatTimestamp } from "../../utils/helpers";
import { handleData } from "../../utils/data-handler";
import { RECENT_SESSION, useAppContext } from "../../store/appContext";
import { useSearchParams } from "react-router-dom";

export default function MetaDataAccordion() {
    const {
        selectedApp,
        logEntries,
        selectedEntry,
        setSelectedEntry,
        setFilteringCallback,
        automaticSessionUpdate
    } = useAppContext();

    const [isOpen, setIsOpen] = useState(false);
    const [isTagged, setIsTagged] = useState(selectedEntry.tagged);
    const [isRecent, setIsRecent] = useState(false);

    // Ref to store the latest selectedEntry
    const selectedEntryRef = useRef(selectedEntry);

    // For handling session and app ID URL params
    const [searchParams, setSearchParams] = useSearchParams();

    // Update ref whenever selectedEntry changes
    useEffect(() => {
        selectedEntryRef.current = selectedEntry;
    }, [selectedEntry]);

    // Set up keyboard navigation once when logEntries changes
    useEffect(() => {
        if (!logEntries) return;

        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
                handleNavigation("prev");
            } else if (event.key === "ArrowRight") {
                handleNavigation("next");
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);

    }, [logEntries]);

    // Handle flagged state - UI only
    useEffect(() => {
        setIsTagged(selectedEntry.tagged);
    }, [selectedEntry]);


    useEffect(() => {
        checkIfRecent();

        if (!automaticSessionUpdate) {
            return;
        }

        const interval = setInterval(() => {
            checkIfRecent();
        }, RECENT_SESSION);

        return () => clearInterval(interval);
    }, [selectedEntry, automaticSessionUpdate]);

    const checkIfRecent = () => {
        const timeNow = Date.now();
        const endTime = new Date(selectedEntryRef.current?.last_interaction).getTime();
        const recent = timeNow - endTime < RECENT_SESSION;

        if (selectedEntryRef.current) {
            setIsRecent(recent);
        }
    };

    const handleTagged = (flagged) => {
        const api = `${process.env.REACT_APP_API_URL}/sessions/${selectedApp.id}/${selectedEntry.session_id}?tagged=${flagged}`;

        handleData("PATCH", api, null, {}, "text")
            .then(() => {
                setIsTagged(prevTagged => !prevTagged);
                setFilteringCallback({state: true, pageReset: false});

                toast.success(`Session updated successfully!`, {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((error) => {
                console.error("Error updating session:", error);
                toast.error("Failed to update session");
            });
    };

    const toggleAccordion = () => setIsOpen(prevOpen => !prevOpen);

    // Handle navigation
    const handleNavigation = useCallback((direction) => {
        // Get the index of the currently selected entry
        const entryIndex = logEntries.findIndex(entry => entry.session_id === selectedEntryRef.current.session_id);

        // Ensure the entryIndex is valid
        if (entryIndex === -1) return;

        // Determine the next index based on the direction
        const nextIndex = direction === 'prev' ? entryIndex - 1 : entryIndex + 1;

        // Check if the next index is within bounds
        const isNextIndexValid = (direction === 'prev' && entryIndex > 0) || (direction === 'next' && entryIndex < logEntries.length - 1);

        if (!isNextIndexValid) return;

        // Get the next entry based on the direction
        const nextEntry = logEntries[nextIndex];

        // Update selected entry and search params
        setSelectedEntry(nextEntry);
        setSearchParams({
            appId: selectedApp.id,
            sessionId: nextEntry.session_id
        });
    }, [logEntries]);


    const copySessionID = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            toast.success('Session ID copied to clipboard!', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }).catch(() => {
            toast.error('Failed to copy session ID.', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        });
    };

    const copyEntryURL = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url)
            .then(() => {
                toast.success('Deep link copied to clipboard!', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch((error) => {
                toast.error('Failed to copy deep link.', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            });
    }

    return (
        <div className="meta-data" style={{maxHeight: isOpen ? '300px' : '40px',}}>
            <div className="meta-data-header">

                <div className="headline">
                    <span className="session-id" title="Click to copy complete session ID"
                          onClick={() => copySessionID(selectedEntry.session_id)}>
                        Session: <b>&nbsp; {selectedEntry.session_id.slice(-6)}</b> <ContentCopyIcon className="copy-icon" />
                    </span>
                    {(isRecent && automaticSessionUpdate) && <span className="recent-indicator"></span>}

                    <div className="navigation">
                        <IconButton size="small" onClick={() => handleNavigation('prev')}>
                            <ArrowBackIosNewIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleNavigation('next')}>
                            <ArrowBackIosNewIcon fontSize="inherit" />
                        </IconButton>
                    </div>

                    <div className="share-icon">
                        <IconButton size="small" onClick={copyEntryURL}>
                            <ShareIcon fontSize="inherit" />
                        </IconButton>
                    </div>

                    {isTagged ? (
                        <IconButton className="tagged" onClick={() => handleTagged(false)}>
                            <AssistantPhotoIcon fontSize="small" style={{color: 'black'}}/>
                        </IconButton>
                    ) : (
                        <IconButton className="tagged" onClick={() => handleTagged(true)}>
                            <OutlinedFlagIcon fontSize="small" style={{color: 'black'}}/>
                        </IconButton>
                    )}
                </div>
            </div>

            <div className="meta-data-content" style={{opacity: isOpen ? 1 : 0, maxHeight: isOpen ? '200px' : '0'}}>
                <div className="row">
                    <span className='key'>Start: </span>
                    <span>{formatTimestamp(selectedEntry.created_at)}</span>
                </div>
                <div className="row">
                    <span className='key'>End: </span>
                    {isRecent && automaticSessionUpdate ? (
                        <span>-</span>
                    ) : (
                        <span>{formatTimestamp(selectedEntry.last_interaction)}</span>
                    )}
                </div>
                <div className="row">
                    <span className='key'>Duration: </span>
                    {isRecent && automaticSessionUpdate ? (
                        <span>-</span>
                    ) : (
                        <span>{calculateChatDuration(selectedEntry)}</span>
                    )}
                </div>
                <div className="row">
                    <span className='key'>Interactions: </span>
                    <span>{selectedEntry.chatLog.length}</span>
                </div>
            </div>

            <span className="toggle-button" onClick={toggleAccordion}>
                <ExpandCircleDownRoundedIcon fontSize="large"
                                             style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
            </span>
        </div>
    )
}
