import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useAppContext } from "../../store/appContext";

export default function FlagFilter() {
    const { filters, setFilters, setFilteringCallback } = useAppContext();

    // Handle filter change
    const setFlagFilter = (event) => {
        const selectedValue = event.target.value;

        setFilters((prevFilters) => ({
            ...prevFilters,
            flagged: selectedValue === "null" ? null : selectedValue
        }));

        setFilteringCallback({state: true, pageReset: true});
    }

    return (
        <FormControl size="small" className='form-control dropdown'>
            <InputLabel id="flag-dropdown">Filter Flag</InputLabel>
            <Select
                label="Filter Flag"
                variant="standard"
                labelId="flag-dropdown"
                id="flag-dropdown"
                value={filters.flagged === null ? "null" : filters.flagged}
                onChange={setFlagFilter}
            >
                <MenuItem value="null">Show All</MenuItem>
                <MenuItem value={true}>Show flagged</MenuItem>
                <MenuItem value={false}>Show unflagged</MenuItem>
            </Select>
        </FormControl>
    );
}
