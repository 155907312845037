import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { defaultFilters, useAppContext } from "../../store/appContext";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const datePickerProps = {
    textField: { size: "small", variant: "standard" },
    actionBar: { actions: ["accept", "today", "clear"] },
};

export default function AnalyticFilters({filterTypeCallback, filterPeriodCallback}) {
    const {selectedApp, filters} = useAppContext();

    const [filterType, setFilterType] = useState('all');
    const [filterPeriod, setFilterPeriod] = useState(defaultFilters.default_period);
    const [filterTimeRange, setFilterTimeRange] = useState([]);

    // Reset Filters to initial values if App change
    useEffect(() => {
        if (selectedApp) {
            setFilterType('all');
            setFilterTimeRange([])
            setFilterPeriod(defaultFilters.default_period);
        }
    }, [selectedApp]);

    const handleFilterTypeChange = (filterType) => {
        setFilterType(filterType);
        filterTypeCallback(filterType);
    }

    const handleFilterPeriodChange = (period) => {
        setFilterTimeRange([]);

        const today = dayjs();
        let dateFrom;

        if (period === "year") {
            dateFrom = today.startOf("year");
        } else if (period !== "custom") {
            dateFrom = today.subtract(Number(period), "day");
        }

        if (dateFrom) {
            filterPeriodCallback({from: dayjs(dateFrom).toISOString(), to: dayjs(today).toISOString()})
        }

        setFilterPeriod(period);
    };

    const handleDateChange = (newDate, type) => {
        setFilterTimeRange((prevFilters) => {
            const updatedFilters = { ...prevFilters, [type]: newDate ? dayjs(newDate).toISOString() : null };

            // Reset "to" date if "from" date is updated to a later value
            if (type === "from" && updatedFilters.to && dayjs(newDate).isAfter(dayjs(updatedFilters.to))) {
                updatedFilters.to = null;
            }

            return updatedFilters;
        });
    };

    const handleTimeRangeFilter = () => {
        if (filterTimeRange.from && filterTimeRange.to) {
            filterPeriodCallback(filterTimeRange)
        }
    }

    return (
        <>
            <FormControl size="small" className='form-control dropdown'>
                <InputLabel id="chart-type">Chart Type</InputLabel>
                <Select
                    label="Chart Type"
                    variant="standard"
                    labelId="chart-type"
                    id="chart-type"
                    value={filterType}
                    onChange={(e) => handleFilterTypeChange(e.target.value)}
                >
                    <MenuItem value="all">Show all Charts</MenuItem>
                    <MenuItem value="conversations">Conversations</MenuItem>
                    <MenuItem value="duration">Duration</MenuItem>
                    <MenuItem value="interactions">Interactions</MenuItem>
                    <MenuItem value="empty">Empty Conversations</MenuItem>
                </Select>
            </FormControl>

            <FormControl size="small" className='form-control dropdown'>
                <InputLabel id="period">Period</InputLabel>
                <Select
                    label="Period"
                    variant="standard"
                    labelId="period"
                    id="period"
                    value={filterPeriod}
                    onChange={(e) => handleFilterPeriodChange(e.target.value)}
                >
                    <MenuItem disabled value="Period">Period</MenuItem>
                    <MenuItem value="7">Last 7 days</MenuItem>
                    <MenuItem value="30">Last 30 days</MenuItem>
                    <MenuItem value="year">Last year</MenuItem>
                    <MenuItem value="custom">Custom</MenuItem>
                </Select>
            </FormControl>

            {/* DateTime Pickers */}
            <div className="time-range">
                <DateTimePicker
                    size="small"
                    label="From"
                    value={filterTimeRange.from ? dayjs(filterTimeRange.from) : null}
                    onChange={(newDate) => handleDateChange(newDate, "from")}
                    ampm={false}
                    slotProps={datePickerProps}
                    className="date-picker"
                    minDate={dayjs(filters.first_session)} // validation
                    maxDate={dayjs(filters.last_session).subtract(1, "day")} // validation
                    disabled={filterPeriod !== 'custom'}
                    onAccept={() => handleTimeRangeFilter()}
                />
                <DateTimePicker
                    size="small"
                    label="To"
                    value={filterTimeRange.to ? dayjs(filterTimeRange.to) : null}
                    onChange={(newDate) => handleDateChange(newDate, "to")}
                    ampm={false}
                    slotProps={datePickerProps}
                    className="date-picker"
                    minDate={dayjs(filterTimeRange.from).add(1, "day")} // validation
                    maxDate={dayjs(filters.last_session)} // validation
                    disabled={filterPeriod !== 'custom'}
                    onAccept={() => handleTimeRangeFilter()}
                />
            </div>
        </>
    );
}
