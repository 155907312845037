import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../store/appContext";

export default function InteractionFilter() {
    const {filters, setFilters, setFilteringCallback} = useAppContext();

    const [minInteraction, setMinInteraction] = useState(2);

    // If filters reset, reset also local state
    // set initial default value of 2
    useEffect(() => {
        if (!filters.min_interaction) {
            setMinInteraction(2);
        }
    }, [filters.min_interaction])

    // Handle filter change
    const handleFilterChange = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            min_interaction: minInteraction
        }));

        setFilteringCallback({state: true, pageReset: true});
    }

    return (
        <div className="interaction-filter">
            <small className="label">
                Minimum interactions: {minInteraction}
            </small>
            <Slider
                className="slider"
                value={minInteraction}
                min={1}
                max={100}
                onChange={(e) => setMinInteraction(e.target.value)}
                onChangeCommitted={handleFilterChange}
                onKeyDown= {(e) => {
                    if (e.key) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                }}
            />
        </div>
    );
}
