import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import React from "react";

export default function NoDataView({selectedAppId}) {
    return (
        <div className="no-data-view">
            {!selectedAppId && 'Select App'}
            {selectedAppId && (
                <>
                    <SentimentDissatisfiedIcon className="icon"/>
                    <p className="text">Nothing found</p>
                </>
            )}
        </div>
    )
}
