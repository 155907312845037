// React
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

// Mui
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Logout from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export default function AccountMenu({ selectedApp }) {
    const { keycloak } = useKeycloak();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (path) => {
        setAnchorEl(null);

        path && navigate(`/${path}`);
    };

    const username = keycloak?.tokenParsed?.name;

    return (
        <React.Fragment>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <span style={{color: "white"}}>{username}</span>
                    <Avatar sx={{ width: 32, height: 32, marginLeft: 1 }}>
                        {username ? username.charAt(0).toUpperCase() : ''}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => handleClose()}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => handleClose()}>
                    <Avatar /> Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleClose("settings")}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                {selectedApp?.id !== 4 && (
                    <>
                        <MenuItem onClick={() => handleClose("analytics")}>
                            <ListItemIcon>
                                <QueryStatsIcon fontSize="small" />
                            </ListItemIcon>
                            Analytics
                        </MenuItem>
                        <MenuItem onClick={() => handleClose("user-management")}>
                            <ListItemIcon>
                                <ManageAccountsIcon fontSize="small" />
                            </ListItemIcon>
                            User Management
                        </MenuItem>
                    </>
                )}
                <MenuItem onClick={() => keycloak.logout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
