import { useState, useEffect, useRef } from 'react';
import { getColumns } from "./columns";
import { useAppContext } from "../../store/appContext";

/**
 * Custom hook to manage table column configurations based on the table's width.
 *
 * This hook:
 * - Observes the table's width using a `ResizeObserver` on a `tableRef`.
 * - Updates the `tableWidth` state whenever the table's width changes.
 * - Sets the `columns` state dynamically based on the current table width,
 *   filtering columns as specified in the `getColumns` function from the `columns` file.
 *
 * @returns {Object} An object containing:
 *   - `tableRef` (ref): A reference to the table DOM element, allowing for width observation.
 *   - `columns` (Array): An array of column configurations filtered based on table width.
 *
 * Usage:
 * Attach `tableRef` to the table component's `ref` attribute, and use `columns`
 * to render columns dynamically based on the current table width.
 *
 * Note:
 * The `tableRef.current` is saved to a `currentTableRef` variable within the effect to avoid
 * stale references in the cleanup function, ensuring the correct DOM node is used
 * even if `tableRef.current` changes during re-renders.
 */
export const useTableColumns = () => {
    const [tableWidth, setTableWidth] = useState(0);
    const [columns, setColumns] = useState([]);
    const [timeNow, setTimeNow] = useState(Date.now());

    const { logEntries, showFirstBotUtterance, filteringCallback, automaticSessionUpdate, selectedApp, setFilteringCallback } = useAppContext();
    const tableRef = useRef(null);
    const showFirstBotUtteranceRef = useRef(showFirstBotUtterance);

    useEffect(() => {
        function updateColumns() {
            if (tableRef.current) {
                const width = tableRef.current.offsetWidth;
                setTableWidth(width);
                setColumns(getColumns(width, showFirstBotUtteranceRef.current, automaticSessionUpdate, logEntries, selectedApp, setFilteringCallback));
            }
        }

        const currentTableRef = tableRef.current;
        const resizeObserver = new ResizeObserver(updateColumns);

        if (currentTableRef) resizeObserver.observe(currentTableRef);

        return () => {
            if (currentTableRef) resizeObserver.unobserve(currentTableRef);
        };
    }, [tableWidth, timeNow, logEntries]); // Ensure columns update when logEntries change

    useEffect(() => {
        if (tableRef.current) {
            const width = tableRef.current.offsetWidth;
            showFirstBotUtteranceRef.current = showFirstBotUtterance;
            setColumns(getColumns(width, showFirstBotUtteranceRef.current, automaticSessionUpdate, logEntries));
        }
    }, [showFirstBotUtterance, filteringCallback, timeNow, logEntries]); // Ensure logEntries updates the table

    useEffect(() => {
        if (!automaticSessionUpdate) {
            setColumns(getColumns(automaticSessionUpdate, logEntries));
            return;
        }

        const interval = setInterval(() => {
            setTimeNow(Date.now());
        }, 3000);

        return () => clearInterval(interval);
    }, [automaticSessionUpdate, logEntries]);

    return { tableRef, columns };
};