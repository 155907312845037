// React
import React, { Fragment, useState } from "react";

// Mui
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormControlLabel, IconButton, Switch } from "@mui/material";

// Own
import { defaultFilters, useAppContext } from "../../store/appContext";


export default function ActionButtons({totalRows, selectedRows, excelDownloadCallback}) {

    const {
        setFilteringCallback,
        filtersActive,
        setFilters,
        selectedApp,
        logEntries,
        showFirstBotUtterance,
        setShowFirstBotUtterance,
    } = useAppContext();

    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState(null);
    const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);

    const downloadMenuOpen = Boolean(downloadMenuAnchorEl);
    const settingsMenuOpen = Boolean(settingsMenuAnchorEl);

    const excelDownloadOptions = [];

    const handleDownloadMenuOpen = (event) => {

        // If we have selected items, trigger download
        // Else, open popup with quantity selection
        if (selectedRows.length > 0) {
            excelDownloadCallback();
        } else {
            setDownloadMenuAnchorEl(downloadMenuAnchorEl ? null : event.currentTarget);
        }
    }

    const handleSettingsMenuOpen = (event) => {
        if (event) {
            setSettingsMenuAnchorEl(event.currentTarget);
        } else {
            setSettingsMenuAnchorEl(null);
        }
    }

    (() => {
        [50, 100, 200, 500, 1000].forEach((option) => {
            if (option < totalRows) {
                excelDownloadOptions.push(option);
            }
        })
    })();

    return (
        <div className='table-buttons'>
            {logEntries && logEntries.length > 0 && (
                <>
                    <IconButton onClick={(event) => handleSettingsMenuOpen(event)}>
                        <SettingsIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={settingsMenuAnchorEl}
                        open={settingsMenuOpen}
                        onClose={() => handleSettingsMenuOpen(null)}
                    >
                        <MenuItem>
                            <FormControlLabel
                                control={<Switch size="small" checked={showFirstBotUtterance}/>}
                                label="Show first bot utterance"
                                labelPlacement="end"
                                onChange={(e) => {
                                    setTimeout(() => {
                                        handleSettingsMenuOpen(null)
                                    }, 200);
                                    setShowFirstBotUtterance(e.target.checked)
                                }}
                            />
                        </MenuItem>

                    </Menu>
                </>
            )}

            {filtersActive > 0 &&
                <div className="reset-filter">
                    <span>Showing {totalRows} results</span>
                    <span className="button"
                          onClick={() => {
                              setFilters({
                                  ...defaultFilters,
                                  first_session: selectedApp.first_interaction,
                                  last_session: selectedApp.last_interaction
                              });
                              setFilteringCallback({state: true, pageReset: true});
                          }}>
                        <FilterAltOffIcon className="icon"/>
                        Reset Filters
                    </span>
                </div>
            }

            <div className="excel-download">
                {selectedRows.length > 0 &&
                    <span className="text">{selectedRows.length} selected</span>
                }
                {selectedApp && logEntries.length > 0 &&
                    <Button
                        className="excel-download-button"
                        variant="outlined"
                        size="small"
                        startIcon={<DownloadIcon/>}
                        onClick={(event) => handleDownloadMenuOpen(event)}>
                        Excel
                    </Button>
                }

                {/* Download selected quantity of items, or all (totalRows) */}
                <Menu
                    className="excel-popper"
                    open={downloadMenuOpen}
                    anchorEl={downloadMenuAnchorEl}
                    onClose={() => setDownloadMenuAnchorEl(null)}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    {excelDownloadOptions.map((quantity) => (
                        <MenuItem key={quantity}
                                  className="list-item"
                                  onClick={() => {
                                      excelDownloadCallback(quantity);
                                      setDownloadMenuAnchorEl(null);
                                  }}>
                            Last {quantity}
                        </MenuItem>
                    ))}
                    <MenuItem className="list-item"
                              onClick={() => {
                                  excelDownloadCallback(totalRows);
                                  setDownloadMenuAnchorEl(null);
                              }}>
                        All conversations ({totalRows})
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}
