// React
import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Own
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";
import Filters from "./components/filters/filters";
import Table from "./components/table/table";
import { useAppContext } from "./store/appContext";

const LoggiApp = () => {
    const {keycloak, initialized, selectedApp, selectedEntry} = useAppContext();

    /**
     * Handle Sidebar resize
     */
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const handleMouseDown = () => setIsResizing(true);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;

            // Calculate new width with a max of 50% of the screen width
            const maxWidth = window.innerWidth * 0.5;
            const newWidth = Math.min(window.innerWidth - e.clientX, maxWidth);

            // Set min and max width
            if (newWidth >= 150 && newWidth <= maxWidth) {
                sidebarRef.current.style.width = `${newWidth}px`;
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
            document.body.classList.remove("no-select");
        };

        if (isResizing) {
            document.body.classList.add("no-select");
        }

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            document.body.classList.remove("no-select"); // Clean up if effect re-runs
        };
    }, [isResizing]);

    if (!(initialized && keycloak.authenticated)) {
        return "";
    }

    return (
        <>
            <Header/>

            <div className="content-wrapper">
                <div className="main-content">
                    {selectedApp && <Filters/>}

                    <Table/>
                </div>

                <div className="sidebar" ref={sidebarRef}>
                    <div className="resizer" onMouseDown={handleMouseDown}/>

                    {selectedEntry && <Sidebar/>}
                </div>
            </div>


            <ToastContainer/>
        </>
    );
};

export default LoggiApp;
