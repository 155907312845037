import keycloak from "../auth/keycloakService";

export async function handleData(method, url, data = null, headers = {}, responseType = "json") {
    try {
        if (keycloak.authenticated) {
            const token = keycloak.token;
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
        }

        const options = {
            method,
            headers: {
                'Accept': 'application/json',
                ...headers,
            },
            credentials: "include",
        };

        if (data && method !== "GET" && method !== "HEAD") {
            options.body = JSON.stringify(data);
            options.headers['Content-Type'] = 'application/json';
        }

        const response = await fetch(url, options);

        if (!response.ok) {
            if (response.status === 401) {
                console.error("Unauthorized! Redirecting to login...");
                await keycloak.login();
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (responseType === "blob") {
            return await response.blob();
        }

        if (responseType === "text") {
            return await response.text();
        }
        return await response.json();
    } catch (error) {
        console.error(`Fetch error: ${error.message}`);
        throw error;
    }
}
