// React
import React from "react";

import Chat from "./chat";
import MetaDataAccordion from "./meta-data-accordion";

export default function Sidebar() {
    return (
        <>
            <MetaDataAccordion  />

            <Chat/>
        </>
    )
}
